import React from 'react';
import { Route, BrowserRouter as Router, Routes, useLocation } from 'react-router-dom';
import Capitulo from './Capitulo';
import GenresPage from './GenresPage';
import Header from './Header';
import Home from './Home';
import Manga from './Manga';
import MangasByGenrePage from './MangasByGenrePage';
import Slide from './Slide';

function App() {
  return (
    <Router>
      <div className="app">
        <div className="hero__object circle"></div>
        <ConditionalHeader /> {/* Renderiza o Header condicionalmente */}
        <ConditionalSlide />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/manga/:book" element={<Manga />} />
          <Route path="/manga/:book/capitulo/:chapter" element={<Capitulo />} />
          <Route path="/generos" element={<GenresPage />} />
          <Route path="/mangas/genero/:genre" element={<MangasByGenrePage />} />
        </Routes>
      </div>
    </Router>
  );
}

// Componente para exibir o Slide apenas na página inicial
function ConditionalSlide() {
  const location = useLocation();
  
  // Verifica se a rota atual é a página inicial
  return location.pathname === '/' ? <Slide /> : null;
}

// Componente para exibir o Header condicionalmente
function ConditionalHeader() {
  const location = useLocation();

  // Verifica se a rota atual é a página de capítulo
  const isChapterPage = location.pathname.includes('/capitulo/');
  
  // Se não estiver na página de capítulo, renderiza o Header
  return !isChapterPage ? <Header /> : null;
}

export default App;
