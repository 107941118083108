import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import io from 'socket.io-client';
import './Slide.css';

const socket = io('http://api.arthurcomics.com');

function Slide() {
      const [mangas, setMangas] = useState([]);
      const [chapters, setChapters] = useState({});
      const [popularMangas, setPopularMangas] = useState([]);
      const [loadingError, setLoadingError] = useState(null);
    
      useEffect(() => {
        const fetchData = async () => {
          try {
            const [mangasResponse, chaptersResponse] = await Promise.all([
              fetch('http://api.arthurcomics.com/api/mangas'),
              fetch('http://api.arthurcomics.com/api/chapters')
            ]);
    
            const mangasData = await mangasResponse.json();
            const chaptersData = await chaptersResponse.json();
    
            // Organizar capítulos por mangá
            const chaptersByManga = chaptersData.reduce((acc, chapter) => {
              if (!acc[chapter.manga]) acc[chapter.manga] = [];
              acc[chapter.manga].push(chapter);
              return acc;
            }, {});
    
            // Ordenar capítulos por data de postagem e pegar os dois últimos
            Object.keys(chaptersByManga).forEach(manga => {
              chaptersByManga[manga] = chaptersByManga[manga]
                .sort((a, b) => new Date(b.postedAt) - new Date(a.postedAt))
                .slice(0, 2);
            });
    
            // Ordenar mangás pela data do capítulo mais recente
            const sortedMangas = mangasData.sort((a, b) => {
              const lastChapterA = chaptersByManga[a.title]?.[0];
              const lastChapterB = chaptersByManga[b.title]?.[0];
              if (!lastChapterA) return 1; // Colocar no final se não houver capítulos
              if (!lastChapterB) return -1; // Colocar no final se não houver capítulos
              return new Date(lastChapterA.postedAt) - new Date(lastChapterA.postedAt);
            });
    
            setMangas(sortedMangas);
            setChapters(chaptersByManga);
    
            // Buscar mangás populares
            const popularResponse = await fetch('http://api.arthurcomics.com/api/mangas/popular');
            const popularData = await popularResponse.json();
            setPopularMangas(popularData);
    
          } catch (error) {
            console.error('Erro ao buscar dados:', error);
            setLoadingError(`Erro ao buscar dados: ${error.message}`);
          }
        };
    
        fetchData();
    
        // Escuta eventos de atualização em tempo real
        socket.on('mangaUpdated', (updatedManga) => {
          setMangas(prevMangas => {
            const index = prevMangas.findIndex(manga => manga._id === updatedManga._id);
            if (index !== -1) {
              return [
                ...prevMangas.slice(0, index),
                updatedManga,
                ...prevMangas.slice(index + 1)
              ];
            }
            return [...prevMangas, updatedManga];
          });
        });
    
        socket.on('chapterAdded', (newChapter) => {
          setChapters(prevChapters => {
            const updatedChapters = { ...prevChapters };
            if (!updatedChapters[newChapter.manga]) {
              updatedChapters[newChapter.manga] = [];
            }
            updatedChapters[newChapter.manga] = [
              ...updatedChapters[newChapter.manga],
              newChapter
            ].sort((a, b) => new Date(a.postedAt) - new Date(a.postedAt)).slice(0, 2);
            return updatedChapters;
          });
        });
    
        socket.on('mangaDeleted', (deletedId) => {
          setMangas(prevMangas => prevMangas.filter(manga => manga._id !== deletedId));
        });
    
        return () => {
          socket.off('mangaUpdated');
          socket.off('chapterAdded');
          socket.off('mangaDeleted');
        };
      }, []);

      const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 10,
        arrows: true,
        appendDots: dots => (
          <div
            style={{
              position: 'absolute',
              bottom: '-30px',
              width: '100%',
              textAlign: 'center'
            }}
          >
            <ul style={{ margin: '0px' }}>{dots}</ul>
          </div>
        ),
        customPaging: i => (
          <div
            style={{
              width: '10px',
              height: '10px',
              borderRadius: '50%',
              background: 'gray',
              border: '1px solid black'
            }}
          />
        )
      };
    
  return (
      <div className="slider-container splide__list sm:mt-2 ">
      <section class="splide col-span-full sm:w-full splide--loop splide--ltr splide--draggable is-active is-overflow is-initialized" id="splide01" aria-roledescription="carousel">
        <Slider {...settings}>
          {mangas.map((manga) => {
             const coverImageUrl = `http://api.arthurcomics.com/${manga.coverImage.replace(/\\/g, '/')}`;
             return(
              <Link key={manga._id} to={`/manga/${encodeURIComponent(manga.title)}`} className='flex justify-center overflow-hidden relative items-center w-full splide__slide sm:rounded-lg group'>
              <div style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${coverImageUrl})` }}
                alt={manga.title}
                className="grid relative z-10 w-full aspect-[0.74/1] h-full bg-center bg-cover bg-white/10 group-hover:sm:opacity-80 rounded-lg transition-all"
              ></div>
              </Link>
            );
          })}
        </Slider></section>
        </div>
  );
}

export default Slide;
