import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import io from 'socket.io-client';
import './Slide.css'; // Certifique-se de atualizar o CSS também

const socket = io('https://api.arthurcomics.com');

function Slide() {
  const [mangas, setMangas] = useState([]);
  const [chapters, setChapters] = useState({});
  const [popularMangas, setPopularMangas] = useState([]);
  const [loadingError, setLoadingError] = useState(null);
  const [slidesToShow, setSlidesToShow] = useState(5);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setSlidesToShow(1); 
      } else {
        setSlidesToShow(5);
      }
    };

    handleResize(); 
    window.addEventListener('resize', handleResize); 

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [mangasResponse, chaptersResponse] = await Promise.all([
          fetch('https://api.arthurcomics.com/api/mangas'),
          fetch('https://api.arthurcomics.com/api/chapters')
        ]);

        const mangasData = await mangasResponse.json();
        const chaptersData = await chaptersResponse.json();

        const chaptersByManga = chaptersData.reduce((acc, chapter) => {
          if (!acc[chapter.manga]) acc[chapter.manga] = [];
          acc[chapter.manga].push(chapter);
          return acc;
        }, {});

        Object.keys(chaptersByManga).forEach(manga => {
          chaptersByManga[manga] = chaptersByManga[manga]
            .sort((a, b) => new Date(b.postedAt) - new Date(a.postedAt))
            .slice(0, 2);
        });

        const sortedMangas = mangasData.sort((a, b) => {
          const lastChapterA = chaptersByManga[a.title]?.[0];
          const lastChapterB = chaptersByManga[b.title]?.[0];
          if (!lastChapterA) return 1;
          if (!lastChapterB) return -1;
          return new Date(lastChapterA.postedAt) - new Date(lastChapterA.postedAt);
        });

        setMangas(sortedMangas);
        setChapters(chaptersByManga);

        const popularResponse = await fetch('https://api.arthurcomics.com/api/mangas/popular');
        const popularData = await popularResponse.json();
        setPopularMangas(popularData);

      } catch (error) {
        console.error('Erro ao buscar dados:', error);
        setLoadingError(`Erro ao buscar dados: ${error.message}`);
      }
    };

    fetchData();

    socket.on('mangaUpdated', (updatedManga) => {
      setMangas(prevMangas => {
        const index = prevMangas.findIndex(manga => manga._id === updatedManga._id);
        if (index !== -1) {
          return [
            ...prevMangas.slice(0, index),
            updatedManga,
            ...prevMangas.slice(index + 1)
          ];
        }
        return [...prevMangas, updatedManga];
      });
    });

    socket.on('chapterAdded', (newChapter) => {
      setChapters(prevChapters => {
        const updatedChapters = { ...prevChapters };
        if (!updatedChapters[newChapter.manga]) {
          updatedChapters[newChapter.manga] = [];
        }
        updatedChapters[newChapter.manga] = [
          ...updatedChapters[newChapter.manga],
          newChapter
        ].sort((a, b) => new Date(a.postedAt) - new Date(a.postedAt)).slice(0, 2);
        return updatedChapters;
      });
    });

    socket.on('mangaDeleted', (deletedId) => {
      setMangas(prevMangas => prevMangas.filter(manga => manga._id !== deletedId));
    });

    return () => {
      socket.off('mangaUpdated');
      socket.off('chapterAdded');
      socket.off('mangaDeleted');
    };
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow, 
    slidesToScroll: slidesToShow,
    arrows: true, // Habilita as setas
    nextArrow: <SampleArrow />, // Adiciona o componente de seta customizada
    prevArrow: <SampleArrow />,
    appendDots: dots => (
      <div
        style={{
          position: 'absolute',
          bottom: '-30px',
          width: '100%',
          textAlign: 'center'
        }}
      >
        <ul style={{ margin: '0px' }}>{dots}</ul>
      </div>
    ),
    customPaging: i => (
      <div
        style={{
          width: '10px',
          height: '10px',
          borderRadius: '50%',
          background: 'gray',
          border: '1px solid black'
        }}
      />
    )
  };

  return (
    <div className="slider-container splide__list sm:mt-2 ">
      <section className="splide col-span-full sm:w-full splide--loop splide--ltr splide--draggable is-active is-overflow is-initialized" id="splide01" aria-roledescription="carousel">
        <Slider {...settings}>
          {mangas.map((manga) => {
            const coverImageUrl = `https://api.arthurcomics.com/${manga.coverImage.replace(/\\/g, '/')}`;
            return (
              <Link key={manga._id} to={`/manga/${encodeURIComponent(manga.title)}`} className='flex justify-center overflow-hidden relative items-center w-full splide__slide sm:rounded-lg group'>
                <div style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${coverImageUrl})` }}
                  alt={manga.title}
                  className="grid relative z-10 w-full aspect-[0.74/1] h-full bg-center bg-cover bg-white/10 group-hover:sm:opacity-80 rounded-lg transition-all"
                ></div>
              </Link>
            );
          })}
        </Slider>
      </section>
    </div>
  );
}

// Componente de seta customizada
function SampleArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', background: 'transparent', border: 'none' }} // Sem texto, apenas botão
      onClick={onClick}
    />
  );
}

export default Slide;
