import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import io from 'socket.io-client'; // Importar o cliente Socket.IO
import './Livro.css';

const socket = io('https://api.arthurcomics.com');

function Livro() {
  const { book } = useParams();
  const [manga, setManga] = useState(null);
  const [chapters, setChapters] = useState([]);
  const [popularMangas, setPopularMangas] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const updateViews = async () => {
      try {
        const response = await fetch(`https://api.arthurcomics.com/api/mangas/${book}/views`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Erro ao atualizar visualizações');
        }

        const popularResponse = await fetch('https://api.arthurcomics.com/api/mangas/popular');
        if (!popularResponse.ok) {
          throw new Error('Erro ao buscar mangás populares');
        }

        const popularData = await popularResponse.json();
        setPopularMangas(popularData);
      } catch (error) {
        console.error('Erro ao atualizar visualizações:', error);
        setError(error.message);
      }
    };

    updateViews();

    const fetchManga = async () => {
      try {
        const response = await fetch('https://api.arthurcomics.com/api/mangas');
        const data = await response.json();
        const currentManga = data.find(m => m.title.toLowerCase() === book.toLowerCase());
        setManga(currentManga);
      } catch (error) {
        console.error('Erro ao buscar mangá:', error);
        setError(error.message);
      }
    };

    fetchManga();

    const fetchChapters = async () => {
      try {
        const response = await fetch('https://api.arthurcomics.com/api/chapters');
        const data = await response.json();
        const mangaChapters = data.filter(chapter => chapter.manga.toLowerCase() === book.toLowerCase());
        setChapters(mangaChapters);
      } catch (error) {
        console.error('Erro ao buscar capítulos:', error);
        setError(error.message);
      }
    };

    fetchChapters();

    socket.on('mangaUpdated', (updatedManga) => {
      if (updatedManga.title.toLowerCase() === book.toLowerCase()) {
        setManga(updatedManga);
      }
    });

    socket.on('chapterAdded', (newChapter) => {
      if (newChapter.manga.toLowerCase() === book.toLowerCase()) {
        setChapters(prevChapters => [
          ...prevChapters,
          newChapter
        ].sort((a, b) => b.chapterNumber - a.chapterNumber));
      }
    });

    return () => {
      socket.off('mangaUpdated');
      socket.off('chapterAdded');
    };
  }, [book]);

  if (error) {
    return <div>Erro: {error}</div>;
  }

  if (!manga) {
    return <div>Carregando...</div>;
  }

  const bannerImageUrl = `https://api.arthurcomics.com/${manga.bannerImage.replace(/\\/g, '/')}`;
  const coverImageUrl = `https://api.arthurcomics.com/${manga.coverImage.replace(/\\/g, '/')}`;

  return (
    <div className="manga-container grid sm:gap-10 gap-[4vw] w-full 2xl:max-w-[100rem] max-w-6xl mx-auto sm:p-4 p-[4vw]">
      <div className='flex w-full sm:gap-10 gap-[4vw] relative'>
        <div className='grid sm:gap-10 gap-[4vw] w-full h-fit'>
          <div className="manga-banner" style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${bannerImageUrl})` }}></div>
          <div className='flex lg:flex-row flex-col sm:gap-10 gap-[4vw]'>
            <div className='grid sm:gap-10 gap-[4vw]'>
              <div className="manga-imagem lg:block flex">
                <div className="grid border border-white/10 overflow-hidden rounded-xl sm:w-64 w-1/2">
                  <div className="manga-cover bg-[image:--photoURL] aspect-[0.75/1] bg-cover bg-center bg-white/10" style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${coverImageUrl})` }} />
                </div>
              </div>
            </div>
            <div className="manga-details grid sm:gap-6 gap-[4vw] h-fit">
              <div className='grid sm:gap-4 gap-[4vw]'>
                <h1 className='sm:text-6xl sm:leading-[3.75rem] text-[length:10vw] leading-[11vw] font-bold'>{manga.title}</h1>
              </div>
              <div className='flex flex-wrap sm:gap-2 gap-[2vw]'>
                <div className='leading-none h-8 px-3 flex justify-center items-center sm:gap-1.5 gap-[1.5vw] bg-white/10 hover:bg-white/20 transition-all cursor-pointer rounded-lg'>{manga.altTitle}</div>
                <div className='leading-none h-8 px-3 flex justify-center items-center sm:gap-1.5 gap-[1.5vw] bg-white/10 hover:bg-white/20 transition-all cursor-pointer rounded-lg'>
                  <img src="https://api.iconify.design/ph:eye-fill.svg?color=white" className="w-5 -ml-1" /> {manga.views || 0}
                </div>
                <div className='leading-none h-8 px-3 flex justify-center items-center sm:gap-1.5 gap-[1.5vw] bg-white/10 hover:bg-white/20 transition-all cursor-pointer rounded-lg'>
                  <img src="https://api.iconify.design/ph:user-fill.svg?color=white" className="w-5 -ml-1" /> {manga.author.join(', ')}
                </div>
                <div className='leading-none h-8 px-3 flex justify-center items-center sm:gap-1.5 gap-[1.5vw] bg-white/10 hover:bg-white/20 transition-all cursor-pointer rounded-lg'>
                  <img src="https://api.iconify.design/ic:baseline-draw.svg?color=white" className="w-5 -ml-1" /> {manga.artist.join(', ')}
                </div>
                {manga.genres.map((genre, index) => (
                  <div className='leading-none h-8 px-3 flex justify-center items-center sm:gap-1.5 gap-[1.5vw] bg-white/10 hover:bg-white/20 transition-all cursor-pointer rounded-lg' key={index}>
                    <Link to={`/mangas/genero/${genre}`}>
                      <span className="genre-item">{genre}</span>
                    </Link>
                  </div>
                ))}
                <div className='leading-none h-8 px-3 flex justify-center items-center sm:gap-1.5 gap-[1.5vw] bg-white/10 hover:bg-white/20 transition-all cursor-pointer rounded-lg'>{manga.type}</div>
              </div>
              <div id="expand_content" className="overflow-hidden">
                <p className="p-4 bg-white/10 rounded-lg">{manga.synopsis}</p>
              </div>
            </div>
          </div>
          <div className="manga-chapters">
            <h2 className='sm:text-2xl text-[5.5vw] font-bold'>Capítulos</h2>
            <div className="grid relative overflow-hidden">
              <div id="chapters_panel" className="grid">
                <div id="chapters" className='grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 sm:gap-4 gap-[3vw]'>
                  {chapters.map(chapter => (
                    <div key={chapter._id}>
                      <Link to={`/manga/${book}/capitulo/${chapter.chapterNumber}`} className='group cursor-pointer relative overflow-hidden flex items-center justify-between sm:gap-4 gap-[4vw] hover:bg-white/20 bg-white/10 rounded-2xl sm:p-2 p-[2vw] transition-all'>
                        <div className='flex items-center sm:gap-4 gap-[4vw]'>
                          <div className="grid h-fit">Capítulo {chapter.chapterNumber}</div>
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="popular-mangas">
          <h2 className='sm:text-2xl text-[5.5vw] font-bold'>Mangás Populares</h2>
          <div className='grid sm:gap-4 gap-[3vw]'>
            {popularMangas.map((popularManga) => (
              <div key={popularManga._id} className='flex items-center gap-2'>
                <img src={`https://api.arthurcomics.com/${popularManga.coverImage.replace(/\\/g, '/')}`} alt={popularManga.title} className='w-16 h-16 object-cover' />
                <Link to={`/manga/${popularManga.title}`}>{popularManga.title}</Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Livro;
