import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import './ReadingPage.css';

function ReadingPage() {
  const { book, chapter } = useParams(); 
  const [chapterImages, setChapterImages] = useState([]);
  const [bookChapters, setBookChapters] = useState([]);
  const [loadingError, setLoadingError] = useState(null);

  useEffect(() => {
    // Fetch chapters data
    fetch('https://api.arthurcomics.com/api/chapters')
      .then((response) => response.json())
      .then((data) => {
        // Filter chapters for the current book
        const currentBookChapters = data.filter((c) => c.manga.toLowerCase() === book.toLowerCase());
        setBookChapters(currentBookChapters);

        // Find the current chapter
        const currentChapter = currentBookChapters.find((c) => c.chapterNumber === chapter);

        if (currentChapter) {
          // Generate image URLs from the content field and sort them numerically
          const imageUrls = currentChapter.content.split(',')
            .map(imagePath => `https://api.arthurcomics.com/${imagePath}`)
            .sort((a, b) => {
              const numA = parseInt(a.match(/\/(\d+)\./)?.[1], 10);
              const numB = parseInt(b.match(/\/(\d+)\./)?.[1], 10);
              return numA - numB;
            });

          setChapterImages(imageUrls);
        } else {
          setLoadingError('Capítulo não encontrado.');
        }

        window.scrollTo(0, 0);
      })
      .catch((err) => {
        console.error('Erro ao buscar capítulos:', err);
        setLoadingError(`Erro ao buscar capítulos: ${err.message}`);
      });
  }, [book, chapter]);

  const getNextChapterLink = () => {
    const currentChapterIndex = bookChapters.findIndex((c) => c.chapterNumber === chapter);
    const nextChapter = bookChapters[currentChapterIndex + 1];
    return nextChapter ? `/manga/${book}/capitulo/${nextChapter.chapterNumber}` : '#';
  };

  const getPreviousChapterLink = () => {
    const currentChapterIndex = bookChapters.findIndex((c) => c.chapterNumber === chapter);
    const previousChapter = bookChapters[currentChapterIndex - 1];
    return previousChapter ? `/manga/${book}/capitulo/${previousChapter.chapterNumber}` : '#';
  };

  const getBookPageLink = () => {
    return `/manga/${book}`;
  };

  return (
    <div>
  <div className="reading-page">
      <div className="navigation-buttons-top flex gap-4 justify-center items-center">
        <Link to={getPreviousChapterLink()} className="prev-chapter flex sm:w-full w-1/2 sm:max-w-[10rem] justify-center items-center h-11 gap-3 bg-white/10 hover:bg-white/20 transition-all cursor-pointer rounded-full">
          <img class="w-6" src="https://api.iconify.design/mdi:arrow-left.svg?color=white" />
        </Link>
        <Link to={getBookPageLink()} className="back-to-book">
          Voltar para o Mangá
        </Link>
        <Link to={getNextChapterLink()} className="next-chapter flex sm:w-full w-1/2 sm:max-w-[10rem] justify-center items-center h-11 gap-3 bg-white/10 hover:bg-white/20 transition-all cursor-pointer rounded-full">
          <img class="w-6" src="https://api.iconify.design/mdi:arrow-right.svg?color=white" />
        </Link>
      </div>
      {loadingError && <p>{loadingError}</p>}
      <div className="reader-area">
        {chapterImages.length > 0 ? (
          chapterImages.map((image, index) => (
            <img key={index} src={image} alt={`Capítulo ${chapter} - Imagem ${index + 1}`} />
          ))
        ) : (
          <p>Carregando imagens...</p>
        )}
      </div>
      <div className="navigation-buttons flex gap-4 justify-center items-center">
        <Link to={getPreviousChapterLink()} className="prev-chapter flex sm:w-full w-1/2 sm:max-w-[10rem] justify-center items-center h-11 gap-3 bg-white/10 hover:bg-white/20 transition-all cursor-pointer rounded-full">
          <img class="w-6" src="https://api.iconify.design/mdi:arrow-left.svg?color=white" />
        </Link>
        <Link to={getBookPageLink()} className="back-to-book">
          Voltar para o Mangá
        </Link>
        <Link to={getNextChapterLink()} className="next-chapter flex sm:w-full w-1/2 sm:max-w-[10rem] justify-center items-center h-11 gap-3 bg-white/10 hover:bg-white/20 transition-all cursor-pointer rounded-full">
          <img class="w-6" src="https://api.iconify.design/mdi:arrow-right.svg?color=white" />
        </Link>
      </div>
       </div>
    </div>
  );
}

export default ReadingPage;
