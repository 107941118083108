import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import io from 'socket.io-client';
import './Home.css';

const socket = io('https://api.arthurcomics.com');

function Home() {
  const [mangas, setMangas] = useState([]);
  const [chapters, setChapters] = useState({});
  const [popularMangas, setPopularMangas] = useState([]);
  const [loadingError, setLoadingError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
  try {
    const [mangasResponse, chaptersResponse] = await Promise.all([
      fetch('https://api.arthurcomics.com/api/mangas'),
      fetch('https://api.arthurcomics.com/api/chapters')
    ]);

    const mangasData = await mangasResponse.json();
    const chaptersData = await chaptersResponse.json();

    // Organizar capítulos por mangá
    const chaptersByManga = chaptersData.reduce((acc, chapter) => {
      if (!acc[chapter.manga]) acc[chapter.manga] = [];
      acc[chapter.manga].push(chapter);
      return acc;
    }, {});

    // Ordenar capítulos por data de postagem e pegar os dois últimos
    Object.keys(chaptersByManga).forEach(manga => {
      chaptersByManga[manga] = chaptersByManga[manga]
        .sort((a, b) => new Date(b.postedAt) - new Date(a.postedAt))
        .slice(0, 2);
    });

    // Ordenar mangás pela data do capítulo mais recente
    const sortedMangas = mangasData.sort((a, b) => {
      const lastChapterA = chaptersByManga[a.title]?.[0];
      const lastChapterB = chaptersByManga[b.title]?.[0];
      if (!lastChapterA) return 1; // Colocar no final se não houver capítulos
      if (!lastChapterB) return -1; // Colocar no final se não houver capítulos
      return new Date(lastChapterA.postedAt) - new Date(lastChapterA.postedAt);
    });

    setMangas(sortedMangas);
    setChapters(chaptersByManga);

    // Buscar mangás populares
    const popularResponse = await fetch('https://api.arthurcomics.com/api/mangas/popular');
    const popularData = await popularResponse.json();
    setPopularMangas(popularData);

  } catch (error) {
    console.error('Erro ao buscar dados:', error);
    setLoadingError(`Erro ao buscar dados: ${error.message}`);
  }
};


    fetchData();

    // Escuta eventos de atualização em tempo real
    socket.on('mangaUpdated', (updatedManga) => {
      setMangas(prevMangas => {
        const index = prevMangas.findIndex(manga => manga._id === updatedManga._id);
        if (index !== -1) {
          return [
            ...prevMangas.slice(0, index),
            updatedManga,
            ...prevMangas.slice(index + 1)
          ];
        }
        return [...prevMangas, updatedManga];
      });
    });

    socket.on('chapterAdded', (newChapter) => {
      setChapters(prevChapters => {
        const updatedChapters = { ...prevChapters };
        if (!updatedChapters[newChapter.manga]) {
          updatedChapters[newChapter.manga] = [];
        }
        updatedChapters[newChapter.manga] = [
          ...updatedChapters[newChapter.manga],
          newChapter
        ].sort((a, b) => new Date(a.postedAt) - new Date(a.postedAt)).slice(0, 2);
        return updatedChapters;
      });
    });

    socket.on('mangaDeleted', (deletedId) => {
      setMangas(prevMangas => prevMangas.filter(manga => manga._id !== deletedId));
    });

    return () => {
      socket.off('mangaUpdated');
      socket.off('chapterAdded');
      socket.off('mangaDeleted');
    };
  }, []);

  return (
    <div className='home-container'>
      <div className='flex  sm:gap-10 gap-[4vw] w-full 2xl:max-w-[100rem] max-w-6xl mx-auto  sm:p-4 p-[4vw] relative'>
      <div className='container-1 grid sm:gap-10 gap-[4vw] w-full h-fit'>
        <div className='title-home'>
          <h1>Atualizados Recentemente</h1>
        </div>
        {loadingError && <p>{loadingError}</p>}
        <div className='grid lg:grid-cols-5 md:grid-cols-4 sm:grid-cols-3 grid-cols-2 sm:gap-x-4 sm:gap-y-8 gap-4'>
          {mangas.length > 0 ? (
            mangas.map((manga) => (
              <div key={manga._id} className='manga-item '>
                <div className='group text-left relative h-fit w-full overflow-hidden grid transition-all sm:hover:bg-white/10 sm:hover:ring-white/10 sm:hover:ring-[0.5rem] sm:hover:scale-95 sm:hover:rounded-sm'>
                <Link to={`/manga/${encodeURIComponent(manga.title)}`} className='cover-image-link'>
                  <img src={`https://api.arthurcomics.com/${manga.coverImage}`} alt={manga.title} className='manga-cover'/>
                </Link></div>
                <Link to={`/manga/${encodeURIComponent(manga.title)}`} className='title-link'>
                  <h2>{manga.title}</h2>
                </Link>
                
                <div className='chapters'>
                  <ul>
                    {(chapters[manga.title] || []).map((chapter) => (
                      <li key={chapter._id}>
                        <Link to={`/manga/${encodeURIComponent(manga.title)}/capitulo/${chapter.chapterNumber}`}>
                          Capítulo {chapter.chapterNumber}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
                </div>
            ))
          ) : (
            <p>Carregando mangás...</p>
          )}
        </div>
        </div>
      <div className='barralateral max-w-[25rem] w-full 2xl:flex flex-col hidden gap-4 h-fit'>
      <div className='title-home flex  justify-between items-center h-fit sm:mb-6'>
      <h1 className='sm:text-2xl text-[5.5vw] font-bold'>Populares</h1>
        </div>
        
        <div className='grid gap-4 h-fit'>
          {popularMangas.length > 0 ? (
            popularMangas.map((manga, index) => (
              <div key={manga._id} className={index === 0 ? 'group bg-white/10 hover:bg-white/20 rounded-xl transition-all border border-white/5 grid overflow-hidden' : 'group flex gap-3.5 sm:p-3.5 p-[2.5vw] items-center bg-white/10 hover:bg-white/20 rounded-xl transition-all aspect-[2/0.5] border border-white/5 overflow-hidden'}>
                <Link to={`/manga/${encodeURIComponent(manga.title)}`}>
                  {index === 0 ? (
                    <img src={`https://api.arthurcomics.com/${manga.bannerImage}`} alt={manga.title} className='popular-manga-banner grid transition-all aspect-[2/1] w-full bg-white/10 bg-no-repeat bg-cover bg-center '/>
                  ) : (
                    <img src={`https://api.arthurcomics.com/${manga.coverImage}`} alt={manga.title} className='popular-manga-cover grid transition-all aspect-[0.75/1] border border-white/10 h-full rounded-lg bg-white/10 bg-no-repeat bg-cover bg-center'/>
                  )}
                </Link>
                <div className={index === 0 ? 'flex  gap-3.5 items-center justify-start sm:py-2.5 sm:px-3.5 py-[2vw] px-[2.5vw] w-full bg-white/10 p-4 rounded-xl' : 'flex  gap-3.5 items-center justify-start sm:py-2.5 sm:px-3.5 py-[2vw] px-[2.5vw] w-full'}>
                  <div className={index === 0 ? 'text-3xl font-bold' : 'text-3xl font-bold'}>{index === 0 ? '#1' : index + 1}</div>
                  <div className='grid h-fit gap-1'>
                    <h3 className='text-base truncate leading-5 break-words'>{manga.title}</h3>
                    <div className='flex flex-wrap gap-1.5 mt-0.5'><span className='bg-white/10 border border-white/5 backdrop-blur-3xl w-fit h-fit px-1 rounded-md text-xs capitalize'>{manga.type}</span></div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>Carregando mangás populares...</p>
          )}
        </div>
      </div>
    </div></div>
  );
}

export default Home;
