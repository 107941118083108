import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import logo from './img/un.png';

function Header() {
  const [menus, setMenus] = useState([]);

  useEffect(() => {
    // Buscar menus
    const fetchMenus = async () => {
      try {
        const response = await fetch('http://api.arthurcomics.com/api/menus');
        if (!response.ok) {
          throw new Error('Erro na resposta da API');
        }
        const data = await response.json();
        console.log('Dados recebidos da API:', data); // Log para ver a estrutura dos dados
        if (Array.isArray(data)) {
          setMenus(data);
        } else {
          console.warn('A resposta da API não é um array:', data);
        }
      } catch (error) {
        console.error('Erro ao carregar menus:', error);
      }
    };

    fetchMenus();
  }, []);

  return (
    <header className="header">
      <Link to="/" className="logo-link">
        <img src={logo} alt="Logo" className="logo" />
      </Link>
      <nav>
        <ul className="nav-list">
          {menus.length > 0 ? (
            menus.map(menu => (
              <li key={menu._id}>
                <Link to={menu.url} className="nav-link">
                  {menu.label}
                </Link>
              </li>
            ))
          ) : (
            <li><span>Não há menus disponíveis.</span></li>
          )}
        </ul>
      </nav>
    </header>
  );
}

export default Header;
