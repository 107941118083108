import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import './ReadingPage.css';

function ReadingPage() {
  const { book, chapter } = useParams(); 
  const [chapterImages, setChapterImages] = useState([]);
  const [bookChapters, setBookChapters] = useState([]);
  const [loadingError, setLoadingError] = useState(null);

  useEffect(() => {
    fetch('http://api.arthurcomics.com/api/chapters')
      .then((response) => response.json())
      .then(async (data) => {
        const currentBookChapters = data.filter((c) => c.manga.toLowerCase() === book.toLowerCase());
        setBookChapters(currentBookChapters);

        const currentChapter = currentBookChapters.find((c) => c.chapterNumber === chapter);

        if (currentChapter) {
          const chapterId = currentChapter._id.$oid || currentChapter._id;
          const chapterPath = `http://api.arthurcomics.com/imgs/mangas/${book}/${chapterId}`;

          try {
            const imageUrls = [];
            const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'webp'];
            let i = 0;
            let imageFound = true;

            // Tentando encontrar imagens até que não sejam mais encontradas
            while (imageFound) {
              imageFound = false;

              for (const ext of imageExtensions) {
                // Tenta números com 1, 2 e 3 dígitos
                for (let digits = 1; digits <= 3; digits++) {
                  const formattedNumber = i.toString().padStart(digits, '0');
                  const imageUrl = `${chapterPath}/${formattedNumber}.${ext}`;

                  try {
                    const response = await fetch(imageUrl, { method: 'HEAD' });

                    if (response.ok) {
                      imageUrls.push(imageUrl);
                      imageFound = true;
                      break; // Encontre pelo menos uma imagem e pare o loop interno
                    }
                  } catch (error) {
                    console.error(`Erro ao verificar a URL da imagem: ${imageUrl}`, error);
                  }
                }

                if (imageFound) {
                  break; // Encontre pelo menos uma imagem e pare o loop externo
                }
              }

              i++;
            }

            // Ordena as imagens pela numeração extraída do nome
            imageUrls.sort((a, b) => {
              const numA = parseInt(a.match(/\/(\d+)\./)?.[1] || '0', 10);
              const numB = parseInt(b.match(/\/(\d+)\./)?.[1] || '0', 10);
              return numA - numB;
            });

            setChapterImages(imageUrls);
          } catch (error) {
            console.error('Erro ao buscar imagens do capítulo:', error);
            setLoadingError('Erro ao carregar imagens.');
          }
        } else {
          setLoadingError('Capítulo não encontrado.');
        }

        window.scrollTo(0, 0);
      })
      .catch((err) => {
        console.error('Erro ao buscar capítulos:', err);
        setLoadingError(`Erro ao buscar capítulos: ${err.message}`);
      });
  }, [book, chapter]);

  const getNextChapterLink = () => {
    const currentChapterIndex = bookChapters.findIndex((c) => c.chapterNumber === chapter);
    const nextChapter = bookChapters[currentChapterIndex + 1];
    return nextChapter ? `/manga/${book}/capitulo/${nextChapter.chapterNumber}` : '#';
  };

  const getPreviousChapterLink = () => {
    const currentChapterIndex = bookChapters.findIndex((c) => c.chapterNumber === chapter);
    const previousChapter = bookChapters[currentChapterIndex - 1];
    return previousChapter ? `/manga/${book}/capitulo/${previousChapter.chapterNumber}` : '#';
  };

  const getBookPageLink = () => {
    return `/manga/${book}`;
  };

  return (
    <div className="reading-page">
      <div className="navigation-buttons-top">
        <Link to={getPreviousChapterLink()} className="prev-chapter">
          Capítulo Anterior
        </Link>
        <Link to={getBookPageLink()} className="back-to-book">
          Voltar para o Mangá
        </Link>
        <Link to={getNextChapterLink()} className="next-chapter">
          Próximo Capítulo
        </Link>
      </div>
      {loadingError && <p>{loadingError}</p>}
      <div className="reader-area">
        {chapterImages.length > 0 ? (
          chapterImages.map((image, index) => (
            <img key={index} src={image} alt={`Capítulo ${chapter} - Imagem ${index + 1}`} />
          ))
        ) : (
          <p>Carregando imagens...</p>
        )}
      </div>
      <div className="navigation-buttons">
        <Link to={getPreviousChapterLink()} className="prev-chapter">
          Capítulo Anterior
        </Link>
        <Link to={getBookPageLink()} className="back-to-book">
          Voltar para o Mangá
        </Link>
        <Link to={getNextChapterLink()} className="next-chapter">
          Próximo Capítulo
        </Link>
      </div>
    </div>
  );
}

export default ReadingPage;
