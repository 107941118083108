import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import io from 'socket.io-client'; // Importar o cliente Socket.IO
import './Livro.css';

const socket = io('https://api.arthurcomics.com');

function Livro() {
  const { book } = useParams();
  const [manga, setManga] = useState(null);
  const [chapters, setChapters] = useState([]);
  const [popularMangas, setPopularMangas] = useState([]);
  const [error, setError] = useState(null);
  const [reordering, setReordering] = useState(false); // Para controlar o estado de reordenação
  const [reorderedChapters, setReorderedChapters] = useState([]); // Para controlar os capítulos reordenados

  useEffect(() => {
    const updateViews = async () => {
      try {
        const response = await fetch(`https://api.arthurcomics.com/api/mangas/${book}/views`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Erro ao atualizar visualizações');
        }

        const popularResponse = await fetch('https://api.arthurcomics.com/api/mangas/popular');
        if (!popularResponse.ok) {
          throw new Error('Erro ao buscar mangás populares');
        }

        const popularData = await popularResponse.json();
        setPopularMangas(popularData);
      } catch (error) {
        console.error('Erro ao atualizar visualizações:', error);
        setError(error.message);
      }
    };

    updateViews();

    const fetchManga = async () => {
      try {
        const response = await fetch('https://api.arthurcomics.com/api/mangas');
        const data = await response.json();
        const currentManga = data.find(m => m.title.toLowerCase() === book.toLowerCase());
        setManga(currentManga);
      } catch (error) {
        console.error('Erro ao buscar mangá:', error);
        setError(error.message);
      }
    };

    fetchManga();

    const fetchChapters = async () => {
      try {
        const response = await fetch('https://api.arthurcomics.com/api/chapters');
        const data = await response.json();
        const mangaChapters = data.filter(chapter => chapter.manga.toLowerCase() === book.toLowerCase())
          .sort((a, b) => b.chapterNumber - a.chapterNumber); // Ordena do maior para o menor
        setChapters(mangaChapters);
        setReorderedChapters(mangaChapters); // Inicializa os capítulos reordenados
      } catch (error) {
        console.error('Erro ao buscar capítulos:', error);
        setError(error.message);
      }
    };

    fetchChapters();

    socket.on('mangaUpdated', (updatedManga) => {
      if (updatedManga.title.toLowerCase() === book.toLowerCase()) {
        setManga(updatedManga);
      }
    });

    socket.on('chapterAdded', (newChapter) => {
      if (newChapter.manga.toLowerCase() === book.toLowerCase()) {
        setChapters(prevChapters => [
          ...prevChapters,
          newChapter
        ].sort((a, b) => b.chapterNumber - a.chapterNumber));
      }
    });

    return () => {
      socket.off('mangaUpdated');
      socket.off('chapterAdded');
    };
  }, [book]);

  if (error) {
    return <div>Erro: {error}</div>;
  }

  if (!manga) {
    return <div>Carregando...</div>;
  }

  const bannerImageUrl = `https://api.arthurcomics.com/${manga.bannerImage.replace(/\\/g, '/')}`;
  const coverImageUrl = `https://api.arthurcomics.com/${manga.coverImage.replace(/\\/g, '/')}`;

  // Função para reordenar capítulos manualmente
  const handleReorderChapters = (index, direction) => {
    const newOrder = [...reorderedChapters];
    const swapIndex = direction === 'up' ? index - 1 : index + 1;
    [newOrder[index], newOrder[swapIndex]] = [newOrder[swapIndex], newOrder[index]]; // Swap chapters
    setReorderedChapters(newOrder);
  };

  return (
    <div className="manga-container grid sm:gap-10 gap-[4vw] w-full 2xl:max-w-[100rem] max-w-6xl mx-auto sm:p-4 p-[4vw]">
      <div className='flex w-full sm:gap-10 gap-[4vw] relative'>
        <div className='grid sm:gap-10 gap-[4vw] w-full h-fit'>
          <div className="manga-banner" style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${bannerImageUrl})` }}></div>
          <div className='flex lg:flex-row flex-col sm:gap-10 gap-[4vw]'>
            <div className='grid sm:gap-10 gap-[4vw]'>
              <div className="manga-imagem lg:block flex">
                <div className="grid border border-white/10 overflow-hidden rounded-xl sm:w-64 w-1/2">
                  <div className="manga-cover bg-[image:--photoURL] aspect-[0.75/1] bg-cover bg-center bg-white/10" style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${coverImageUrl})` }} />
                </div>
              </div>
            </div>
            <div className="manga-details grid sm:gap-6 gap-[4vw] h-fit">
              <div className='grid sm:gap-4 gap-[4vw]'>
                <h1 className='sm:text-6xl sm:leading-[3.75rem] text-[length:10vw] leading-[11vw] font-bold'>{manga.title}</h1>
              </div>
              <div className='flex flex-wrap sm:gap-2 gap-[2vw]'>
                <div className='leading-none h-8 px-3 flex justify-center items-center sm:gap-1.5 gap-[1.5vw] bg-white/10 hover:bg-white/20 transition-all cursor-pointer rounded-lg'>{manga.altTitle}</div>
                <div className='leading-none h-8 px-3 flex justify-center items-center sm:gap-1.5 gap-[1.5vw] bg-white/10 hover:bg-white/20 transition-all cursor-pointer rounded-lg'>
                  <img src="https://api.iconify.design/ph:eye-fill.svg?color=white" className="w-5 -ml-1" /> {manga.views || 0}
                </div>
                <div className='leading-none h-8 px-3 flex justify-center items-center sm:gap-1.5 gap-[1.5vw] bg-white/10 hover:bg-white/20 transition-all cursor-pointer rounded-lg'>
                  <img src="https://api.iconify.design/ph:user-fill.svg?color=white" className="w-5 -ml-1" /> {manga.author.join(', ')}
                </div>
                <div className='leading-none h-8 px-3 flex justify-center items-center sm:gap-1.5 gap-[1.5vw] bg-white/10 hover:bg-white/20 transition-all cursor-pointer rounded-lg'>
                  <img src="https://api.iconify.design/ic:baseline-draw.svg?color=white" className="w-5 -ml-1" /> {manga.artist.join(', ')}
                </div>
                {manga.genres.map((genre, index) => (
                  <div className='leading-none h-8 px-3 flex justify-center items-center sm:gap-1.5 gap-[1.5vw] bg-white/10 hover:bg-white/20 transition-all cursor-pointer rounded-lg' key={index}>
                    <Link to={`/mangas/genero/${genre}`}>
                      <span className="genre-item">{genre}</span>
                    </Link>
                  </div>
                ))}
                <div className='leading-none h-8 px-3 flex justify-center items-center sm:gap-1.5 gap-[1.5vw] bg-white/10 hover:bg-white/20 transition-all cursor-pointer rounded-lg'>{manga.type}</div>
              </div>
              <div id="expand_content" className="overflow-hidden">
                <p className="p-4 bg-white/10 rounded-lg">{manga.synopsis}</p>
              </div>
            </div>
          </div>

          <div className="manga-chapters">
            <h2 className='sm:text-2xl text-[5.5vw] font-bold'>Capítulos</h2>
            <button onClick={() => setReordering(!reordering)}>
              {reordering ? "Salvar Ordem" : "Reordenar Capítulos"}
            </button>
            <div className="grid relative overflow-hidden">
              <div id="chapters_panel" className="grid">
                <div id="chapters" className='grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 sm:gap-4 gap-[3vw]'>
                  {reorderedChapters.map((chapter, index) => (
  <div key={chapter._id} className="chapter-thumbnail">
    <Link to={`/manga/${book}/capitulo/${chapter.chapterNumber}`} className='group cursor-pointer relative overflow-hidden flex items-center justify-between sm:gap-4 gap-[4vw] hover:bg-white/20 bg-white/10 rounded-2xl sm:p-2 p-[2vw] transition-all'> 
      <div className='flex items-center sm:gap-4 gap-[4vw]'>
        <div className="border inline-table border-white/10 bg-white/10 overflow-hidden rounded-xl">
  {chapter.thumbnail && (
    <div
      style={{ 
        backgroundImage: `url(https://api.arthurcomics.com/${chapter.thumbnail.replace(/\\/g, '/')})`,
      }}
      className="chapter-thumbnail-image"
    ></div>
  )}
</div>

      </div>
      <div className="grid h-fit">
        <span className="flex gap-1 justify-start items-center overflow-hidden">
          <span className="text-sm truncate">Capítulo {chapter.chapterNumber}</span>
        </span>
      </div>
    </Link>
    {reordering && (
      <div className="chapter-reorder-controls">
        <button onClick={() => handleReorderChapters(index, 'up')} disabled={index === 0}>
          ↑
        </button>
        <button onClick={() => handleReorderChapters(index, 'down')} disabled={index === reorderedChapters.length - 1}>
          ↓
        </button>
      </div>
    )}
  </div>
))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='barralateral max-w-[25rem] w-full 2xl:flex flex-col hidden gap-4 h-fit'>
      <div className='title-home flex  justify-between items-center h-fit sm:mb-6'>
      <h1 className='sm:text-2xl text-[5.5vw] font-bold'>Populares</h1>
        </div>
        
        <div className='grid gap-4 h-fit'>
          {popularMangas.length > 0 ? (
            popularMangas.map((manga, index) => (
              <div key={manga._id} className={index === 0 ? 'group flex gap-3.5 sm:p-3.5 p-[2.5vw] items-center bg-white/10 hover:bg-white/20 rounded-xl transition-all aspect-[2/0.5] border border-white/5 overflow-hidden' : 'group flex gap-3.5 sm:p-3.5 p-[2.5vw] items-center bg-white/10 hover:bg-white/20 rounded-xl transition-all aspect-[2/0.5] border border-white/5 overflow-hidden'}>
                <Link to={`/manga/${encodeURIComponent(manga.title)}`}>
                  {index === 0 ? (
                    <img src={`https://api.arthurcomics.com/${manga.coverImage}`} alt={manga.title} className='popular-manga-cover grid transition-all aspect-[0.75/1] border border-white/10 h-full rounded-lg bg-white/10 bg-no-repeat bg-cover bg-center '/>
                  ) : (
                    <img src={`https://api.arthurcomics.com/${manga.coverImage}`} alt={manga.title} className='popular-manga-cover grid transition-all aspect-[0.75/1] border border-white/10 h-full rounded-lg bg-white/10 bg-no-repeat bg-cover bg-center'/>
                  )}
                </Link>
                <div className={index === 0 ? 'flex  gap-3.5 items-center justify-start sm:py-2.5 sm:px-3.5 py-[2vw] px-[2.5vw] w-full bg-white/10 p-4 rounded-xl' : 'flex  gap-3.5 items-center justify-start sm:py-2.5 sm:px-3.5 py-[2vw] px-[2.5vw] w-full'}>
                  <div className={index === 0 ? 'text-3xl font-bold' : 'text-3xl font-bold'}>{index === 0 ? '#1' : index + 1}</div>
                  <div className='grid h-fit gap-1'>
                    <h3 className='text-base truncate leading-5 break-words'>{manga.title}</h3>
                    <div className='flex flex-wrap gap-1.5 mt-0.5'><span className='bg-white/10 border border-white/5 backdrop-blur-3xl w-fit h-fit px-1 rounded-md text-xs capitalize'>{manga.type}</span></div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>Carregando mangás populares...</p>
          )}
        </div>
      </div>
      </div>
    </div>
  );
}

export default Livro;
